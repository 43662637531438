import { Button } from "@headlessui/react";
import {
  ContentCopy,
  FacebookRounded,
  Instagram,
  LinkedIn,
  Twitter,
  WhatsApp,
  Telegram,
  Email,
} from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import { toast } from "react-toastify";

const SocialShare = ({ shareUrl }) => {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    toast.success("Link copied successfully!");
  };

  return (
    <div className="w-full flex flex-col items-center justify-center gap-2 p-2">
      <div className="flex items-center justify-center sm:justify-between gap-y-3 flex-wrap mb-2">
        <h2 className="text-lg font-bold text-[var(--text-color-base)]">
          Share on:
        </h2>
        <div className="flex flex-wrap gap-x-5 gap-y-3 items-center justify-center ml-5">
          <FacebookShareButton
            url={shareUrl}
            hashtag="#AmazingTool"
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <FacebookRounded className="!w-6 !h-6" />
          </FacebookShareButton>
          <TwitterShareButton
            url={`Hi there,\n\nCheck out this amazing tool I found: ${shareUrl}\n\nCheers!`}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Twitter className="!w-6 !h-6" />
          </TwitterShareButton>
          <LinkedinShareButton
            url={"shareUrl"}
            title={`Check out this amazing tool I found`}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <LinkedIn className="!w-6 !h-6" />
          </LinkedinShareButton>
          <InstapaperShareButton
            url={`Hi there,\n\nCheck out this amazing tool I found: ${shareUrl}\n\nCheers!`}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Instagram className="!w-6 !h-6" />
          </InstapaperShareButton>
          <WhatsappShareButton
            url={`Hi there,\n\nCheck out this amazing tool I found: ${shareUrl}\n\nCheers!`}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <WhatsApp className="!w-6 !h-6" />
          </WhatsappShareButton>
          <TelegramShareButton
            url={`Hi there,\n\nCheck out this amazing tool I found: ${shareUrl}\n\nCheers!`}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Telegram className="!w-6 !h-6" />
          </TelegramShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={"Check out this amazing tools!"}
            body={`Hi there,\n\nCheck out this amazing tool I found: ${shareUrl}\n\nCheers!`}
            separator={" \n"}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Email className="!w-6 !h-6" />
          </EmailShareButton>
        </div>
      </div>
      <Button
        onClick={handleCopy}
        className="text-sm font-medium text-[var(--text-color-base)] text-center"
      >
        Copy Link <ContentCopy className="ml-1 !h-4 !w-4" />
      </Button>
    </div>
  );
};

export default SocialShare;

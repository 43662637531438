import axios from "axios";
import { toast } from "react-toastify";
import { nodeServerPath } from "../../constant/api";

export const getUser = async (userData, setUser) => {
  try {
    const url = `${nodeServerPath}/api/user/${userData.username}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${userData.accessToken}` },
    });
    const { data } = response;
    setUser(data);
    return data;
  } catch (error) {
    console.error(
      "Error fetching user data:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateUser = async (userData, data, onUpdated) => {
  try {
    const url = `${nodeServerPath}/api/user/${userData.username}`;
    const response = await axios.patch(url, data, {
      headers: { Authorization: `Bearer ${userData.accessToken}` },
    });
    const { user } = response.data;
    onUpdated(user);
    // toast.success("User data successfully updated");
    return user;
  } catch (error) {
    console.error(
      "Error update user data:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

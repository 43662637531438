import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dialog, DialogPanel } from "@headlessui/react";
import { Close, Favorite, Public } from "@mui/icons-material";
import { addToFavourite } from "../../utils/api";
import SocialShare from "../snippets/social-share";
import { nodeServerPath } from "../../constant/api";
import { setToolModalOpen } from "../../reducers/toolsReducer";

const ToolDetailsModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const { selectedTool, toolModalOpen } = useSelector((state) => state.tools);
  const queryParams = new URLSearchParams(location.search);
  const collection = queryParams.get("collection");
  const isCryptoTab = collection === "CryptoTab";

  const [previousPrice, setPreviousPrice] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("Month"); // Default selected period

  const handleAddToFavourite = async (tool) => {
    await addToFavourite(user, tool._id, dispatch);
  };

  const handleCloseModal = () => {
    dispatch(setToolModalOpen(false));
  };

  useEffect(() => {
    if (toolModalOpen) {
      setTimeout(() => {
        document.documentElement.style.overflow = "auto";
      }, 500);
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  }, [toolModalOpen]);

  useEffect(() => {
    if (selectedTool?.stats?.price) {
      setPreviousPrice((prev) =>
        prev === null ? selectedTool.stats.price : prev
      );
    }
  }, [selectedTool]);

  const shareUrl = `${nodeServerPath}/${selectedTool?.name}/details?hash=${selectedTool?.hash}`;

  if (!selectedTool || Object.keys(selectedTool).length === 0) return null;

  const stats = selectedTool?.stats || {}; // Safeguard for 'stats'
  const change = stats?.change || {}; // Safeguard for 'change'
  const categoryRanks = selectedTool?.category_ranks || {};
  const categories = selectedTool?.category || [];

  const periods = [
    { label: "Hour", performance: change.performance_1h, rank: change.rank_1h },
    { label: "Day", performance: change.performance_24h, rank: change.rank_24h },
    { label: "Week", performance: change.performance_weekly, rank: change.rank_weekly },
    { label: "Month", performance: change.performance_monthly, rank: change.rank_monthly },
    { label: "Year", performance: change.performance_yearly, rank: change.rank_yearly },
  ];

  const priceChange =
    previousPrice !== null && stats.price !== null
      ? stats.price > previousPrice
        ? "text-green-500"
        : "text-red-500"
      : "";

  return (
    <Fragment>
      <Dialog
        onClose={handleCloseModal}
        open={toolModalOpen}
        as="div"
        className={`${currentTheme} relative z-[1001] focus:outline-none`}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              className="relative w-full max-w-2xl rounded-2xl p-10 backdrop-blur-xl shadow-filter transition duration-300 ease-out"
            >
              <Button
                onClick={handleCloseModal}
                className="absolute right-4 top-4 text-[var(--text-color-base)] hover:scale-110 transition-all"
              >
                <Close />
              </Button>

              <div className="w-full flex flex-col items-center gap-3">
                {/* Coin/Tool Name */}
                <h1 className="text-2xl font-bold text-gray-200">
                  {selectedTool.name} {isCryptoTab && <span>({selectedTool.symbol})</span>}
                </h1>

                {isCryptoTab && (
                  /* Crypto-Specific Visuals */
                  <Fragment>
                    {/* Rank, Market Cap, 24h Volume, Current Price */}
                    <div className="flex justify-between w-full max-w-md text-center">
                      <div>
                        <p className="text-sm font-medium text-gray-400">Rank</p>
                        <p className="text-lg font-bold text-red-500">
                          {stats.rank || "--"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-400">
                          Market Cap
                        </p>
                        <p className="text-lg font-bold text-gray-200">
                          {stats?.market_cap
                            ? `$${(stats.market_cap / 1e6).toFixed(2)}M`
                            : "--"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-400">
                          24h Volume
                        </p>
                        <p className="text-lg font-bold text-gray-200">
                          {stats?.volume
                            ? `$${(stats.volume / 1e6).toFixed(2)}M`
                            : "--"}
                        </p>
                      </div>
                    </div>

                    {/* Current Price */}
                    <div className="flex items-center gap-2 mt-3">
                      <p className="text-sm font-medium text-gray-400">Price:</p>
                      <p
                        className={`text-lg font-bold transition ${priceChange}`}
                      >
                        ${stats.price?.toFixed(4) || "--"}
                      </p>
                    </div>

                    {/* Chart Placeholder */}
                    <div className="w-full h-40 mt-5 bg-gray-800 rounded-lg flex items-center justify-center">
                      <p className="text-center text-gray-500">
                        Chart Placeholder
                      </p>
                    </div>

                    {/* Performance Metrics (Selectable) */}
                    <div className="flex justify-center items-center w-full max-w-md gap-4 mt-5">
                      {periods.map((metric, index) => (
                        <div
                          key={index}
                          className={`flex flex-col items-center justify-center px-3 py-2 rounded-lg transition cursor-pointer ${
                            selectedPeriod === metric.label
                              ? "bg-blue-500 text-white"
                              : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                          }`}
                          onClick={() => setSelectedPeriod(metric.label)}
                        >
                          <p className="text-xs font-medium">{metric.label}</p>
                          <p className="text-sm font-bold">
                            {typeof metric.performance === "number"
                              ? `${metric.performance}%`
                              : "--"}
                          </p>
                          {typeof metric.rank === "number" && (
                            <p
                              className={`text-xs font-medium flex items-center ${
                                metric.rank > 0
                                  ? "text-green-500"
                                  : metric.rank < 0
                                  ? "text-red-500"
                                  : "text-gray-500"
                              }`}
                            >
                              {metric.rank > 0 && "▲"}
                              {metric.rank < 0 && "▼"}{" "}
                              {Math.abs(metric.rank)}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>

                    {/* Category Ranks */}
                    <div className="w-full mt-5">
                      <h2 className="text-lg font-bold text-gray-200">
                        Category Ranks
                      </h2>
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        {Object.entries(categoryRanks).map(
                          ([category, rank], index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center p-2 bg-gray-700 rounded-lg shadow-sm"
                            >
                              <p className="text-sm font-medium text-gray-300">
                                {category}
                              </p>
                              <p className="text-sm font-bold text-gray-400">
                                Rank: {rank}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}

                {/* Bubble for Non-Crypto Tools */}
                {!isCryptoTab && (
                  <div
                    style={{
                      backgroundImage: `url(/assets/bubble_dark.svg)`,
                    }}
                    className="w-40 h-40 rounded-full backdrop-blur-xl shadow-filter bg-center bg-no-repeat bg-cover text-[var(--text-color-base)] flex flex-col items-center justify-center gap-2"
                  >
                    <h4 className="text-sm font-normal text-center text-gray-300">
                      Total Global Visit
                    </h4>
                    <h1 className="text-2xl font-bold text-center text-gray-200">
                      {stats?.["total-visits"]?.toLocaleString() || 0}
                    </h1>
                  </div>
                )}

                {/* Common Visuals (Both Crypto and Non-Crypto) */}
                <div className="w-full flex flex-col gap-3 text-lg font-medium text-gray-300 my-3">
                  <div className="flex gap-x-3">
                    <p className="min-w-[120px] opacity-70">Name:</p>
                    <p>{selectedTool.name}</p>
                  </div>
                  {selectedTool?.globalRank && (
                    <div className="flex gap-x-3">
                      <p className="min-w-[120px] opacity-70">Global Rank:</p>
                      <p>{selectedTool.globalRank}</p>
                    </div>
                  )}
                  {selectedTool.use_case && (
                    <div className="flex gap-x-3">
                      <p className="min-w-[120px] opacity-70">Use Case:</p>
                      <p>{selectedTool.use_case}</p>
                    </div>
                  )}
                  {selectedTool.description && (
                    <div className="flex gap-x-3">
                      <p className="min-w-[120px] opacity-70">Description:</p>
                      <p>{selectedTool.description}</p>
                    </div>
                  )}
                </div>

                {/* Actions */}
                <div className="w-full flex items-center justify-center gap-5 mt-4">
                  <Button
                    onClick={() => handleAddToFavourite(selectedTool)}
                    className="flex items-center justify-center gap-2 py-[10px] px-3 rounded-full border-2 border-[var(--text-color-base)] text-[var(--text-color-base)] transition-all hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:text-white shadow-2xl hover:shadow-[#9C3CD6]/40"
                  >
                    <Favorite sx={{ display: { xs: "none", sm: "block" } }} />
                    Add To Favorite
                  </Button>
                  <Link
                    state={{ data: selectedTool }}
                    to={`/${selectedTool.name}/details?hash=${selectedTool?.hash}`}
                    onClick={handleCloseModal}
                    className="flex items-center justify-center gap-2 py-[10px] px-3 rounded-full border-2 border-[var(--primary-color)] bg-[var(--primary-color)] text-white shadow-2xl hover:shadow-[#9C3CD6]/40 transition-all"
                  >
                    <Public sx={{ display: { xs: "none", sm: "block" } }} />
                    View Details
                  </Link>
                </div>

                <div className="w-full h-px bg-[var(--border-color)] my-2" />

                {/* Social Share */}
                <div className="w-full">
                  <SocialShare shareUrl={shareUrl} />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ToolDetailsModal;

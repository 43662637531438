import React, { useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import AvatarMenus from "./avatar-menus";
import HeaderLeft from "./header-left";
import NavbarItems from "./navbar-items";
import AuthModal from "../../components/modals/auth-modal";
import ThemeSwitcher from "../../components/forms/theme-switcher";

export default function Header() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { user } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false);
  const { currentCategory } = useSelector((state) => state.global);
  const { currentTheme } = useSelector((state) => state.theme);

  const toggleDropdown = () => {
    setIsMobileHeaderOpen((prev) => !prev);
  };

  return (
    <header className="w-full flex justify-between items-center gap-2 bg-[var(--bg-color)] py-3 px-3 md:px-8 border-b border-[var(--border-color)] sticky top-0 z-20">
      {/* Header Left Part */}
      <HeaderLeft />
      {currentCategory && (
        <h4
          className="text-white bg-gradient-to-r from-blue-500 to-purple-600 
        whitespace-nowrap border border-transparent py-1 px-4 
        rounded-full shadow-lg text-sm font-medium tracking-wide 
        hover:shadow-xl hover:scale-105 transition-all duration-300 ease-in-out hidden
        xl:flex items-center gap-2"
        >
          {currentCategory}
        </h4>
      )}

      {/* Mobile Menu Icon */}
      <button
        className="lg:hidden text-[var(--text-color-base)] "
        onClick={toggleDropdown}
      >
        <MenuIcon />
      </button>

      <div
        className={`flex flex-col flex-grow-0 lg:flex-row justify-start lg:justify-end items-start lg:items-center p-5 lg:p-0 gap-3 lg:static ease-in-out bg-[var(--bg-color)] absolute left-0 right-0 lg:h-auto h-screen ${
          isMobileHeaderOpen ? " top-0" : "top-[-1000px]"
        } `}
      >
        <div className="w-full flex justify-between items-center lg:hidden mb-4">
          <img
            src={
              currentTheme === "light"
                ? "/assets/logo-light.png"
                : "/assets/logo-dark.png"
            }
            className="w-12 h-12 min-w-12"
            alt="Logo"
          />

          <button
            className="text-[var(--text-color-base)] "
            onClick={toggleDropdown}
          >
            <CloseIcon />
          </button>
        </div>
        {currentCategory && (
          <h4
            className="text-white bg-gradient-to-r from-blue-500 to-purple-600 
        whitespace-nowrap border border-transparent py-1 px-4 
        rounded-full shadow-lg text-sm font-medium tracking-wide 
        hover:shadow-xl hover:scale-105 transition-all duration-300 ease-in-out 
        flex items-center gap-2 lg:hidden"
          >
            {currentCategory}
          </h4>
        )}

        <NavbarItems setIsMobileHeaderOpen={setIsMobileHeaderOpen} />
        <div className="flex items-center gap-3">
          {!isAuthenticated || user?.authMethod === "LOCAL" ? (
            <button
              onClick={() => setOpenModal(true)}
              className="w-max flex items-center gap-2 text-white font-semibold px-3 py-2 rounded-full bg-[var(--primary-color)] overflow-hidden whitespace-nowrap text-ellipsis transition-all"
            >
              <img
                src="/assets/google-icon.png"
                alt="Google Logo"
                className="h-6 w-6"
              />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                Sign in
              </span>
            </button>
          ) : (
            <AvatarMenus />
          )}

          <ThemeSwitcher />
        </div>
      </div>

      {/* Auth Modal */}
      <AuthModal open={openModal} setOpen={setOpenModal} />
    </header>
  );
}

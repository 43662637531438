import React, { Fragment } from "react";
import useCanvas from "../../hooks/useCanvasBubbles";
import Loader from "../snippets/loader";
import ToolDetailsModal from "../modals/tool-details-modal";
import { useSelector } from "react-redux";
import AITable from "../snippets/table";
import TopBar from "../snippets/top-bar";

const ToolBubble = ({ tools, loading, error }) => {
  const canvasRef = useCanvas(null, tools, null);
  const { currentTheme } = useSelector((state) => state.theme);
  const { showOnlyTable, currentCategory } = useSelector(
    (state) => state.global
  );

  if (loading) {
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Some thing is not working fine</div>;
  }

  if (tools.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <h4 className="text-base text-[var(--text-color-base)]">
          No tools found!
        </h4>
      </div>
    );
  }

  return (
    <Fragment>
      {currentCategory && <TopBar />}
      <canvas
        key={currentTheme}
        className={`${
          currentTheme === "light"
            ? "bg-[var(--bg-color)] bg-cover bg-no-repeat"
            : "bg-[#111117]"
        } bg-contain h-full`}
        ref={canvasRef}
        style={{
          backgroundImage:
            currentTheme === "light"
              ? "url('/assets/banner-light-bg.png')"
              : "",
          backgroundColor: currentTheme !== "light" ? "bg-[#111117]" : "",
          width: "100%",
        }}
      />

      {tools.length > 0 && (
        <AITable
          showOnlyTable={showOnlyTable}
          loading={loading}
          tools={tools}
        />
      )}

      <ToolDetailsModal />
    </Fragment>
  );
};

export default ToolBubble;

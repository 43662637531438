import React from "react";
import ToolBubble from "../../components/bubbles/tool-bubble";
import { useFetchBubbles } from "../../hooks/useFetchBubbles";

const PopularPage = () => {
  const { loading, tools, error } = useFetchBubbles("popular");

  return <ToolBubble {...{ tools, loading, error }} />;
};

export default PopularPage;

import {combineReducers} from 'redux';
import authReducer from './authReducer';
import globalReducer from './globalReducer';
import themeReducer from './themeReducer';
import toolsReducer from './toolsReducer';
import preferenceReducer from './preferenceReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    global: globalReducer,
    theme: themeReducer,
    tools: toolsReducer,
    preference: preferenceReducer,
});

export default rootReducer;
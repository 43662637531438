import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setBlueprints } from "../reducers/globalReducer";

const usePaginationDropdown = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { blueprints } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.auth);
  const totalItems = blueprints.totalItems || 0;
  const [itemPerPage, setItemPerPage] = useState("");
  const [startNumber, setStartNumber] = useState(1);
  const [paginationRanges, setPaginationRanges] = useState([]);
  const [startValue, setStartValue] = useState(1);
  const [endValue, setEndValue] = useState(0);
  const [selectedRange, setSelectedRange] = useState("");

  // Extract values from the current page blueprint
  const BUBBLES_PER_PAGE = user?.bubblesPerPage || blueprints.perPage || 100;
  const currentPage = blueprints.currentPage || 1;

  const handlePerPageApply = () => {
    dispatch(
      setBlueprints({
        ...blueprints,
        currentPage: 1,
        perPage: itemPerPage,
        startNumber,
      })
    );

    const newStartValue = 1;
    const newEndValue = Math.min(itemPerPage, totalItems);
    const newSelectedRange = `${newStartValue}-${newEndValue}`;

    setStartValue(newStartValue);
    setEndValue(newEndValue);
    setSelectedRange(newSelectedRange);

    const perPage = itemPerPage > 0 ? Number(itemPerPage) : BUBBLES_PER_PAGE;
    const ranges = [];
    for (let i = 1; i <= totalItems; i += perPage) {
      const start = i;
      const end = Math.min(i + perPage - 1, totalItems);
      ranges.push({ start, end, label: `${start}-${end}` });
    }
    setPaginationRanges(ranges.slice(0, 10));
  };

  const onChangeRange = (value) => {
    if (value === "custom") {
      setSelectedRange("custom");
      return;
    }

    const newStartNumber = 1;
    setStartNumber(newStartNumber);
    const [start] = value.split("-").map(Number);
    const newPage = Math.ceil(start / itemPerPage);

    setStartValue(start);
    setSelectedRange(value);

    dispatch(
      setBlueprints({
        ...blueprints,
        currentPage: newPage,
        startNumber: newStartNumber,
      })
    );
  };

  const handleRangeApply = () => {
    setSelectedRange("custom");
    // Validate the input value
    if (isNaN(startNumber) || startNumber < 0 || startNumber > totalItems) {
      return;
    }

    // Ensure itemPerPage is a valid number
    const perPage = itemPerPage > 0 ? itemPerPage : BUBBLES_PER_PAGE;

    // Calculate the page and end value
    const newPage = Math.ceil(startNumber / perPage);
    const end = Math.min(startNumber + perPage - 1, totalItems);

    // Update state with valid values
    setStartValue(startNumber);
    setEndValue(end);

    // Dispatch the updated pagination values
    dispatch(
      setBlueprints({
        ...blueprints,
        currentPage: newPage,
        perPage, // Use valid perPage
        startNumber,
      })
    );
  };

  // Initialize pagination ranges when component mounts
  useEffect(() => {
    if (totalItems > 0) {
      const perPage = itemPerPage > 0 ? itemPerPage : BUBBLES_PER_PAGE; // Ensure a valid perPage value
      const ranges = [];
      for (let i = 1; i <= totalItems; i += perPage) {
        const start = i;
        const end = Math.min(i + perPage - 1, totalItems);
        ranges.push({ start, end, label: `${start}-${end}` });
      }
      setPaginationRanges(ranges.slice(0, 10)); // Limit to the first 10 ranges or adjust as needed
    }
  }, [totalItems, itemPerPage, BUBBLES_PER_PAGE]);

  // Reset itemPerPage & startNumber on first mount AND on every URL change
  useEffect(() => {
    setItemPerPage(100);
    setStartNumber(1);
    setStartValue(1);
    setEndValue(0);
    setSelectedRange("");
  }, [location.pathname, location.search]);

  // Select the first pagination range whenever the URL changes
  //    or when new paginationRanges are calculated
  useEffect(() => {
    if (paginationRanges.length > 0) {
      const firstRangeLabel = paginationRanges[0].label;
      // If you always want to overwrite `selectedRange`, remove the `if (!selectedRange)` check
      if (!selectedRange) {
        setSelectedRange(firstRangeLabel);
      }
    }
  }, [paginationRanges, location.pathname, location.search, selectedRange]);

  useEffect(() => {
    setStartValue((currentPage - 1) * BUBBLES_PER_PAGE + 1);
    setEndValue(Math.min(currentPage * BUBBLES_PER_PAGE, totalItems));
  }, [currentPage, BUBBLES_PER_PAGE, totalItems]);

  useEffect(() => {
    if (selectedRange !== "custom") {
      const matchingRange = `${startValue}-${endValue}`;
      if (matchingRange !== "0-0" && selectedRange !== matchingRange) {
        setSelectedRange(matchingRange);
      }
    }
  }, [startValue, endValue, selectedRange]);

  return {
    totalItems,
    startValue,
    endValue,
    itemPerPage,
    setItemPerPage,
    handlePerPageApply,
    paginationRanges,
    selectedRange,
    onChangeRange,
    startNumber,
    setStartNumber,
    handleRangeApply,
  };
};

export default usePaginationDropdown;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentCategory: null,
  showOnlyTable: false,
  collection: "FutureTools",
  blueprints: {
    currentPage: 1,
    perPage: 100,
    totalPages: 1,
    totalItems: 100,
    startNumber: 0,
  },
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setShowOnlyTable: (state, action) => {
      state.showOnlyTable = action.payload;
    },
    setCollection: (state, action) => {
      state.collection = action.payload;
    },
    setBlueprints: (state, action) => {
      // Merge updates into the blueprints object
      state.blueprints = {
        ...state.blueprints,
        ...action.payload, // Apply updates dynamically
      };
    },
    resetBlueprints: (state) => {
      // Reset blueprints to the initial state
      state.blueprints = {
        currentPage: 1,
        perPage: 100,
        totalPages: 1,
        totalItems: 100,
        startNumber: 1,
      };
    },
  },
});

export const {
  setCurrentCategory,
  setShowOnlyTable,
  setCollection,
  setBlueprints,
  resetBlueprints,
} = globalSlice.actions;

export default globalSlice.reducer;

import { formatNumber } from "..";
import { bubbleColor } from "../../constant/bubble";

export const resizeCanvas = (canvas) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  canvas.width = screenWidth;
  canvas.height = screenHeight;
};

export const getMousePos = (canvas, evt) => {
  const rect = canvas.getBoundingClientRect();
  return {
    x: (evt.clientX - rect.left) * (canvas.width / rect.width),
    y: (evt.clientY - rect.top) * (canvas.height / rect.height),
  };
};

export const isInsideBubble = (pos, bubble) => {
  const dist = Math.sqrt((pos.x - bubble.x) ** 2 + (pos.y - bubble.y) ** 2);
  return dist < bubble.size;
};

export const drawBubble = (context, bubble, theme, collection) => {
  context.beginPath();

  const isCryptoTab = collection === "CryptoTab"

  // Initialize the animation properties if not already set
  if (bubble.growthStart === undefined) {
    bubble.growthStart = Date.now(); // Record the start time of growth animation
    bubble.startSize = 50; // Start from size
    bubble.startX = bubble.x - 10; // Starting position (adjust as needed)
    bubble.startY = bubble.y - 10; // Starting position (adjust as needed)
  }

  const growthDuration = 1500; // second growth animation duration
  const elapsed = Date.now() - bubble.growthStart;
  const growthProgress = Math.min(elapsed / growthDuration, 1); // Normalize to [0, 1]

  // Interpolate size and position using the growth progress
  bubble.currentSize =
    bubble.startSize + (bubble.size - bubble.startSize) * growthProgress;
  const currentX =
    bubble.startX + (bubble.x - bubble.startX) * growthProgress;
  const currentY =
    bubble.startY + (bubble.y - bubble.startY) * growthProgress;

  // Determine if the bubble is blinking
  const isBlinking = bubble.isBlinking;
  const blinkFactor = isBlinking ? Math.sin(Date.now() / 200) * 0.5 + 1 : 1;

  // Set up colors for the fill based on hover and theme
  const isHovered = bubble.targetSize > bubble.size;

  const hoverShadowStart =
    theme === "light"
      ? bubbleColor.lightHoverShadowStart
      : bubbleColor.darkHoverShadowStart;
  const hoverShadowMiddle =
    theme === "light"
      ? bubbleColor.lightHoverShadowMiddle
      : bubbleColor.darkHoverShadowMiddle;
  const hoverShadowEnd =
    theme === "light"
      ? bubbleColor.lightHoverShadowEnd
      : bubbleColor.darkHoverShadowEnd;
  const hoverShadow =
    theme === "light"
      ? bubbleColor.lightHoverShadow
      : bubbleColor.darkHoverShadow;

  // Add three random gradient color sets
  const gradientColors = [
    {
      start: "#27b92705",
      middle: "#27b92720",
      end: "#27b927DD",
    },
    {
      start: "#ffd30005",
      middle: "#ffd30020",
      end: "#ffd300DD",
    },
    {
      start: "#27b92705",
      middle: "#27b92720",
      end: "#27b927DD",
    },
  ];

  // Randomly select a gradient set for each bubble
  if (!bubble.gradientIndex) {
    bubble.gradientIndex = Math.floor(Math.random() * gradientColors.length);
  }
  const selectedGradient = gradientColors[bubble.gradientIndex];

  const gradient = context.createRadialGradient(
    currentX,
    currentY,
    bubble.currentSize * 0.3,
    currentX,
    currentY,
    bubble.currentSize
  );

  gradient.addColorStop(
    0,
    isHovered ? hoverShadowStart : selectedGradient.start
  );
  gradient.addColorStop(
    0.7,
    isHovered ? hoverShadowMiddle : selectedGradient.middle
  );
  gradient.addColorStop(
    1,
    isHovered ? hoverShadowEnd : selectedGradient.end
  );

  // Outer shadow
  context.shadowColor = isHovered
    ? hoverShadow
    : theme === "light"
    ? bubbleColor.lightShadow
    : bubbleColor.darkShadow;
  context.shadowBlur = isHovered ? 30 : 20;
  context.shadowOffsetX = isHovered ? 7 : 5;
  context.shadowOffsetY = isHovered ? 7 : 5;

  // Draw the filled bubble
  context.arc(currentX, currentY, bubble.currentSize, 0, Math.PI * 2, false);
  context.fillStyle = gradient;
  context.fill();
  context.closePath();

  // Draw the blinking border if the bubble is blinking
  if (isBlinking) {
    context.beginPath();
    context.arc(
      currentX,
      currentY,
      bubble.currentSize + 5,
      0,
      Math.PI * 2,
      false
    ); // Slightly larger for the border
    context.lineWidth = 5 * blinkFactor; // Pulsing border thickness
    context.strokeStyle =
      theme === "light" ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"; // Bright border
    context.stroke();
    context.closePath();
  }

  // Reset shadow properties for text drawing
  context.shadowColor = "transparent";
  context.shadowBlur = 0;
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;

  // Draw text inside the bubble
  if (typeof bubble.text === "string") {
    const maxTextWidth = bubble.currentSize * 3; // Set a max width based on bubble size
    const lines = bubble.text.split("\n");
    context.fillStyle =
      theme === "light"
        ? bubbleColor.lightFontColor
        : bubbleColor.darkFontColor;
    const baseFontSize = bubble.currentSize / 2.5; // Font size proportional to bubble size
    context.font = `${baseFontSize}px Arial`;
    context.textAlign = "center";
    context.textBaseline = "middle";

    // Function to truncate text and add ellipsis
    const truncateText = (text, maxWidth) => {
      let truncatedText = text;
      while (context.measureText(truncatedText).width > maxWidth) {
        truncatedText = truncatedText.slice(0, -1);
      }
      return truncatedText + "...";
    };

    let firstLine = lines[0];
    if (context.measureText(firstLine).width > maxTextWidth) {
      firstLine = truncateText(firstLine, maxTextWidth);
    }
    const textYBase = currentY; // Ensure text position is based on a fixed center point
    context.fillText(firstLine, currentX, textYBase - baseFontSize / 3);

    if (lines[1]) {
      context.font = `${bubble.currentSize / 3}px Arial`;
      const lineGap = baseFontSize / 5;
      let secondLine = lines[1];
      if (
        secondLine.toLowerCase().includes("0") &&
        parseFloat(secondLine) === 0
      ) {
        secondLine = isCryptoTab ? "" : "";
      } else if (context.measureText(secondLine).width > maxTextWidth) {
        secondLine = truncateText(secondLine, maxTextWidth);
      }
      context.fillText(secondLine, currentX, textYBase + baseFontSize / 3 + lineGap);
    }
  }
};


export const drawBubbles = (context, bubbles, theme, collection) => {
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  bubbles.forEach((bubble) => drawBubble(context, bubble, theme, collection));
};

export const createBubbles = (
  type,
  categories,
  canvas,
  baseBubbleSize,
  padding,
  theme
) => {
  const bubbles = [];
  const screenWidth = canvas.width;
  const screenHeight = canvas.height;

  const isMobile = screenWidth < 768;

  const screenRatio = isMobile
    ? Math.min(screenWidth / 320, screenHeight / 568)
    : Math.min(screenWidth / 1920, screenHeight / 1080);

  const dynamicBaseBubbleSize = baseBubbleSize * screenRatio;

  const minBubbleSize = Math.max(10, dynamicBaseBubbleSize * 0.3);
  const maxBubbleSize = isMobile
    ? 30 // Limit for mobile bubbles
    : 120; // Limit for desktop bubbles
  const gap = isMobile ? 10 : 15;

  // Calculate the maximum value for scaling
  const maxValue = Math.max(
    ...categories.map((category) =>
      type === "CATEGORY"
        ? category?.count || 1
        : category?.stats?.["total-visits"] || 1
    )
  );

  if (maxValue === 0) {
    console.warn("All values are zero; using default size.");
  }

  // Create initial bubbles with the scaling formula
  categories.forEach((category) => {
    const value =
      type === "CATEGORY"
        ? category?.count || 1
        : category?.stats?.["total-visits"] || 1;

    // Scale size based on maxValue
    let size = Math.max(
      minBubbleSize,
      (value / maxValue) * dynamicBaseBubbleSize
    );

    // Enforce the maximum size limit
    size = Math.min(size, maxBubbleSize);

    bubbles.push({
      x: 0, // Placeholder, will be adjusted later
      y: 0, // Placeholder, will be adjusted later
      size,
      targetX: 0, // Placeholder for animation
      targetY: 0, // Placeholder for animation
      _id: category._id,
      text:
        type === "CATEGORY"
          ? `${category._id}\n${category.count}`
          : `${category.name}\n${
              formatNumber(category?.stats?.["total-visits"]) || ""
            }`,
      isBlinking: false,
    });
  });

  // Calculate total bubble area and available canvas area
  const totalBubbleArea = bubbles.reduce(
    (sum, bubble) => sum + Math.PI * bubble.size ** 2.3,
    0
  );
  const availableArea = screenWidth * screenHeight * 0.8; // Reserve 80% of the canvas
  const scalingFactor = Math.sqrt(availableArea / totalBubbleArea);

  // Apply the global scaling factor to all bubbles, ensuring they don't exceed the max size
  bubbles.forEach((bubble) => {
    bubble.size *= scalingFactor;
    bubble.size = Math.min(bubble.size, maxBubbleSize); // Enforce max size again after scaling
  });

  // Adjust positions and avoid collisions
  bubbles.forEach((bubble, index) => {
    let x, y, collision;
    let attempts = 0;

    do {
      collision = false;

      x = Math.random() * (screenWidth - 2 * bubble.size) + bubble.size;
      y = Math.random() * (screenHeight - 2 * bubble.size) + bubble.size;

      for (let i = 0; i < index; i++) {
        const otherBubble = bubbles[i];
        const dist = Math.sqrt(
          (x - otherBubble.x) ** 2 + (y - otherBubble.y) ** 2
        );
        if (dist < bubble.size + otherBubble.size + gap) {
          collision = true;
          break;
        }
      }

      if (!collision) {
        bubble.x = x;
        bubble.y = y;
        bubble.targetX = x + Math.random() * 50 - 25;
        bubble.targetY = y + Math.random() * 50 - 25;
      }

      attempts++;
    } while (collision && attempts < 1000);
  });

  return bubbles;
};

export const resolveCollisions = (bubbles, canvas) => {
  const gap = 2; // Minimum gap between bubbles

  const resolveCollision = (b1, b2) => {
    const dx = b2.x - b1.x;
    const dy = b2.y - b1.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    const overlap = b1.size + b2.size + gap - dist;

    if (overlap > 0) {
      const angle = Math.atan2(dy, dx);
      const moveX = (Math.cos(angle) * overlap) / 2;
      const moveY = (Math.sin(angle) * overlap) / 2;

      // Adjust positions and clamp to ensure bubbles stay inside canvas
      b1.x = Math.min(Math.max(b1.x - moveX, b1.size), canvas.width - b1.size);
      b1.y = Math.min(Math.max(b1.y - moveY, b1.size), canvas.height - b1.size);
      b2.x = Math.min(Math.max(b2.x + moveX, b2.size), canvas.width - b2.size);
      b2.y = Math.min(Math.max(b2.y + moveY, b2.size), canvas.height - b2.size);
    }
  };

  for (let i = 0; i < bubbles.length; i++) {
    for (let j = i + 1; j < bubbles.length; j++) {
      resolveCollision(bubbles[i], bubbles[j]);
    }
  }
};

export const animateBubbles = (
  bubbles,
  context,
  canvas,
  drawBubbles,
  theme,
  collection
) => {
  const getRandomOffset = (range) => Math.random() * range - range / 2;

  const setNewTarget = (bubble) => {
    const offsetX = getRandomOffset(30); // 20-30px range
    const offsetY = getRandomOffset(30);

    bubble.targetX = Math.min(
      Math.max(bubble.x + offsetX, bubble.size),
      canvas.width - bubble.size
    );
    bubble.targetY = Math.min(
      Math.max(bubble.y + offsetY, bubble.size),
      canvas.height - bubble.size
    );
  };

  bubbles.forEach((bubble) => {
    if (!bubble.targetX || !bubble.targetY) {
      setNewTarget(bubble);
    }
  });

  const animate = () => {
    const easingFactor = 0.02;

    bubbles.forEach((bubble) => {
      const deltaX = bubble.targetX - bubble.x;
      const deltaY = bubble.targetY - bubble.y;

      bubble.x += deltaX * easingFactor;
      bubble.y += deltaY * easingFactor;

      if (Math.abs(deltaX) < 1 && Math.abs(deltaY) < 1) {
        setNewTarget(bubble);
      }
    });

    resolveCollisions(bubbles, canvas); // Avoid overlaps
    drawBubbles(context, bubbles, theme, collection); // Correctly call drawBubbles

    requestAnimationFrame(animate);
  };

  animate();
};

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetBlueprints, setBlueprints } from "../reducers/globalReducer";
import { getPopularTools, getRecentTools, getTool } from "../utils/api/tool";
import { fetchCategories } from "../utils/api";

export const useFetchBubbles = (toolType) => {
  const dispatch = useDispatch();
  const isFirstLoad = useRef(true);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [tools, setTools] = useState([]);
  const [categories, setCategories] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { currentCategory, collection } = useSelector((state) => state.global);
  const { selectedFilter } = useSelector((state) => state.tools);
  const [error, setError] = useState(null);


  const selectRecentPageInfo = (state) => {
    return {
      currentPage: state.global.blueprints?.currentPage || 1,
      perPage: state.global.blueprints?.perPage || 100,
      startNumber: state.global.blueprints?.startNumber || 0,
    };
  };
  const { currentPage, perPage, startNumber } = useSelector(selectRecentPageInfo);
  const BUBBLES_PER_PAGE = user?.bubblesPerPage || perPage || 100;

  // Fetch Tools
  const fetchTools = async () => {
    setLoading(true);
    // Fetch new data
    try {
      const params = { page: currentPage, perPage: BUBBLES_PER_PAGE, startNumber};
      if (toolType === "recent") {
        await getRecentTools(
          (fetchedTools) => setTools(fetchedTools),
          (fetchedBlueprints) => dispatch(setBlueprints(fetchedBlueprints)),
          params
        );

        setLoading(false);
      }

      if (toolType === "popular") {
        await getPopularTools(
          (fetchedTools) => setTools(fetchedTools),
          (fetchedBlueprints) => dispatch(setBlueprints(fetchedBlueprints)),
          params
        );

        setLoading(false);
      }

      if (toolType === "category") {
        await fetchCategories(
          (fetchedCategories) => setCategories(fetchedCategories),
          (fetchedBlueprints) => dispatch(setBlueprints(fetchedBlueprints)),
          params
        );

        setLoading(false);
      }

      if (toolType === "category-tools") {
        await getTool(
          currentCategory,
          (fetchedTools) => setTools(fetchedTools),
          (fetchedBlueprints) => dispatch(setBlueprints(fetchedBlueprints)),
          params,
          selectedFilter?.filterData
        );

        setLoading(false);
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching tools.");
    }
  };

  // Debounce fetchTools call
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isFirstLoad.current) {
        fetchTools();
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [currentPage, startNumber, BUBBLES_PER_PAGE, selectedFilter?.filterData, collection]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(resetBlueprints());
      setTools([]); // Clear tools on route change
    }
    isFirstLoad.current = false;
  }, [location.pathname, dispatch]);

  return { loading, categories, tools, error };
};

import {
  bubbleContentData,
  bubbleSizeData,
  periodData,
} from "../../constant/filter";
import React, { Fragment } from "react";
import {
  Field,
  Label,
  Input,
  Popover,
  PopoverButton,
  PopoverPanel,
  RadioGroup,
  Radio,
  Button,
} from "@headlessui/react";
import {
  Add,
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

const FilterDropdown = ({ updateConfigState, currentConfig }) => {
  const { currentTheme } = useSelector((state) => state.theme);

  // Ensure default values to avoid runtime errors
  const safeConfig = currentConfig || { filterData: {} };
  const filterData = safeConfig.filterData || {};

  return (
    <Fragment>
      <Popover>
        <Fragment>
          {/* Button to Toggle Popover */}
          <PopoverButton
            title="Filter"
            className="rounded-full bg-[var(--primary-color)] p-2 text-sm text-[var(--text-color-dark)] hover:opacity-80 transition-all"
          >
            <Add />
          </PopoverButton>
          <PopoverPanel
            transition
            anchor="bottom end"
            className={`${currentTheme} min-w-[248px] mt-1 rounded-lg shadow-filter backdrop-blur transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0`}
          >
            <div className="w-full p-4 grid grid-flow-row gap-5">
              <Field>
                <Label className="text-lg font-bold text-[var(--text-color-base)]">
                  Name
                </Label>
                <Input
                  className="mt-1 block w-full rounded-lg border-none bg-white/5 shadow py-1.5 px-3 text-lg font-medium text-[var(--text-color-base)] focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-bgColor/25"
                  value={safeConfig?.label || "Day"}
                  onChange={(e) => {
                    updateConfigState("label", e.target.value, safeConfig);
                  }}
                />
              </Field>

              <Field>
                <Label className="text-lg font-bold text-[var(--text-color-base)]">
                  Period
                </Label>
                <RadioGroup
                  defaultValue={filterData.period}
                  value={filterData.period}
                  onChange={(value) => updateConfigState("period", value)}
                  aria-label="Period"
                  className="space-y-2 mt-1"
                >
                  {periodData.map((item) => (
                    <Radio
                      key={item.name}
                      value={item.value}
                      className="flex cursor-pointer text-[var(--text-color-base)] transition focus:outline-none data-[focus]:outline-none"
                    >
                      <div className="flex w-full items-center gap-2">
                        {filterData.period === item.value ? (
                          <RadioButtonChecked />
                        ) : (
                          <RadioButtonUnchecked />
                        )}
                        <div className="text-lg">
                          <p className="font-medium text-[var(--text-color-base)]">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              </Field>

              <Field>
                <Label className="text-lg font-bold text-[var(--text-color-base)]">
                  Bubble Content
                </Label>
                <RadioGroup
                  defaultValue={filterData.bubbleContent}
                  value={filterData.bubbleContent}
                  onChange={(value) =>
                    updateConfigState("bubbleContent", value)
                  }
                  aria-label="Bubble Content"
                  className="space-y-2 mt-1"
                >
                  {bubbleContentData.map((item) => (
                    <Radio
                      key={item.name}
                      value={item.value}
                      className="flex cursor-pointer text-[var(--text-color-base)] transition focus:outline-none data-[focus]:outline-none"
                    >
                      <div className="flex w-full items-center gap-2">
                        {filterData.bubbleContent === item.value ? (
                          <RadioButtonChecked />
                        ) : (
                          <RadioButtonUnchecked />
                        )}
                        <div className="text-lg">
                          <p className="font-medium text-[var(--text-color-base)]">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              </Field>

              <Field>
                <Label className="text-lg font-bold text-[var(--text-color-base)]">
                  Bubble Size
                </Label>
                <RadioGroup
                  defaultValue={filterData.bubbleSize}
                  value={filterData.bubbleSize}
                  onChange={(value) => updateConfigState("bubbleSize", value)}
                  aria-label="Bubble Size"
                  className="space-y-2 mt-1"
                >
                  {bubbleSizeData.map((item) => (
                    <Radio
                      key={item.name}
                      value={item.value}
                      className="flex cursor-pointer text-[var(--text-color-base)] transition focus:outline-none data-[focus]:outline-none"
                    >
                      <div className="flex w-full items-center gap-2">
                        {filterData.bubbleSize === item.value ? (
                          <RadioButtonChecked className="text-[var(--text-color-base)]" />
                        ) : (
                          <RadioButtonUnchecked />
                        )}
                        <div className="text-lg">
                          <p className="font-medium text-[var(--text-color-base)]">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              </Field>
            </div>
          </PopoverPanel>
        </Fragment>
      </Popover>
    </Fragment>
  );
};

export default FilterDropdown;

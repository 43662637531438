import axios from "axios";
import { nodeServerPath } from "../../constant/api";

export const getMyTools = async (
  setMyTools,
  setMyToolsBlueprints,
  { page, perPage, startNumber } = {},
  accessToken = null
) => {
  try {
    const url = `${nodeServerPath}/api/myTools?page=${page}&perPage=${perPage}&startNumber=${startNumber}`;

    const { data } = await axios.get(
      url,
      accessToken && { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    const { tools, ...blueprints } = data;

    setMyToolsBlueprints(blueprints);
    setMyTools(tools);

    return { tools, blueprints };
  } catch (error) {
    // Log the error and provide detailed error message if available
    console.error(
      "Error fetching my-tools:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getPopularTools = async (
  setMyTools,
  setMyToolsBlueprints,
  { page, perPage, startNumber } = {}
) => {
  try {
    const url = `${nodeServerPath}/api/allTools?page=${page}&perPage=${perPage}&filter=popular&startNumber=${startNumber}`;

    const { data } = await axios.get(url);
    const { tools, ...blueprints } = data;

    setMyToolsBlueprints(blueprints);
    setMyTools(tools);

    return { tools, blueprints };
  } catch (error) {
    // Log the error and provide detailed error message if available
    console.error(
      "Error fetching my-tools:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getRecentTools = async (
  setMyTools,
  setMyToolsBlueprints,
  { page, perPage, startNumber } = {}
) => {
  try {
    const url = `${nodeServerPath}/api/allTools?page=${page}&perPage=${perPage}&filter=recent&startNumber=${startNumber}`;

    const { data } = await axios.get(url);
    const { tools, ...blueprints } = data;

    setMyToolsBlueprints(blueprints);
    setMyTools(tools);

    return { tools, blueprints };
  } catch (error) {
    // Log the error and provide detailed error message if available
    console.error(
      "Error fetching my-tools:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getTool = async (
  currentCategory,
  setTools,
  setBlueprints,
  { page, perPage, startNumber },
  { bubbleSize, bubbleContent, period, bubbleColor } = {}
) => {
  try {
    // Create a query params object
    const params = {
      bubbleSize,
      bubbleContent,
      period,
      bubbleColor,
      startNumber,
      page,
      perPage,
    };

    // Filter out undefined or null values
    const queryString = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    const url = `${nodeServerPath}/api/category/${currentCategory}/tools?${queryString}`;

    const { data } = await axios.get(url);
    const { tools, ...blueprints } = data;

    setTools(tools);
    setBlueprints(blueprints);

    return { tools, blueprints };
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};


export const getToolsDetails = async (hash, setToolInfo) => {
  try {
    const url = `${nodeServerPath}/api/tool/${hash}`;
    const { data } = await axios.get(url);
    setToolInfo(data);
    return { data };
  } catch (error) {
    console.error("Error fetching tools:", error);
  }
};

export const getSimilarTool = async (setSimilarTools, { hash }) => {
  try {
    const url = `${nodeServerPath}/api/similar-tools?hash=${hash}`;
    const { data } = await axios.get(url);
    setSimilarTools(data?.results);
    return { data };
  } catch (error) {
    console.error("Error fetching similar tools:", error);
  }
};

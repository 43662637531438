import { useNavigate, useSearchParams } from "react-router-dom";
import Categories from "./categories";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useCallback } from "react";
import {
  setCollection,
  setCurrentCategory,
  resetBlueprints,
} from "../../reducers/globalReducer";
import Tools from "./tools";
import { setTheme } from "../../reducers/themeReducer";
import axios from "axios";
import { nodeServerPath } from "../../constant/api";

const CategoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentCategory } = useSelector((state) => state.global);
  const { currentTheme } = useSelector((state) => state.theme);
  const { selectedTool, toolModalOpen } = useSelector((state) => state.tools);

  const { collectionName, categoryName, themeName } = useMemo(() => {
    return {
      collectionName: searchParams.get("collection") || "ToolTab",
      categoryName: searchParams.get("category") || currentCategory,
      themeName: searchParams.get("theme") || currentTheme,
    };
  }, [searchParams, currentCategory, currentTheme]);

  const setInitialCollection = () => {
    dispatch(setCurrentCategory(null));
    axios
      .post(`${nodeServerPath}/api/setCollection`, {
        collectionName: "FutureTools",
      })
      .then(() => {
        // Handle successful collection change
        dispatch(setCollection("FutureTools"));
      })
      .catch((error) => {
        console.error("error", error.response);

        // Handle error
        console.error("Error setting collection:", error);
      });
  };
  

  useEffect(() => {
    if (collectionName === "ToolTab") {
      setInitialCollection();
    }
  }, [collectionName]);

  // Reset blueprints when switching between categories and tools
  useEffect(() => {
    dispatch(resetBlueprints());
  }, [currentCategory, dispatch]);

  useEffect(() => {
    if (collectionName !== currentCategory) {
      dispatch(setCollection(collectionName));
    }
  }, [collectionName, currentCategory, dispatch]);

  useEffect(() => {
    if (themeName !== currentTheme) {
      dispatch(setTheme(themeName));
    }
  }, [themeName, currentTheme, dispatch]);

  const constructUrl = useCallback(() => {
    let url = `/ai?collection=${collectionName}`;
    if (currentCategory) {
      url += `&category=${currentCategory}`;
    }
    if (toolModalOpen && selectedTool) {
      url += `&tool=${selectedTool.name}`;
    }
    return url;
  }, [collectionName, currentCategory, selectedTool, toolModalOpen]);

  const currentUrl = useMemo(constructUrl, [constructUrl]);

  useEffect(() => {
    navigate(currentUrl);
  }, [currentUrl, navigate]);

  return (
    <div className="relative">
      {currentCategory ? <Tools /> : <Categories />}
    </div>
  );
};

export default CategoryPage;

export const DEFAULT_FILTER_LIST = [
    {
      value: "weekly",
      label: "Weekly",
      filterData: {
        period: 'week',
        bubbleContent: 'total_visit',
        bubbleSize: 'total_visit',
        bubbleColor: 'green'
      }
    },
    {
      value: "month",
      label: "Monthly",
      filterData: {
        period: 'month',
        bubbleContent: 'total_visit',
        bubbleSize: 'total_visit',
        bubbleColor: 'green'
      },
    },
    {
      value: "year",
      label: "Yearly",
      filterData: {
        period: 'year',
        bubbleContent: 'total_visit',
        bubbleSize: 'total_visit',
        bubbleColor: 'green'
      },
    }
  ];
import { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Favorite } from "@mui/icons-material";
import FeedIcon from "@mui/icons-material/Feed";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableRowsIcon from "@mui/icons-material/TableRows";
import Header from "./header";
import PreferenceModal from "../components/modals/preference-modal";
import { setPreferenceDate } from "../reducers/preferenceReducer";
import { setShowOnlyTable } from "../reducers/globalReducer";

export default function Layout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollContainerRef = useRef(null);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { currentCategory } = useSelector((state) => state.global);
  const { showOnlyTable } = useSelector((state) => state.global);
  const { preferenceDate } = useSelector((state) => state.preference);
  const { currentTheme } = useSelector((state) => state.theme);
  const [isOpen, setIsOpen] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const compareDates = (date1, date2, dayCount = 2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
      throw new Error("Invalid date format");
    }

    const timeDifference = Math.abs(d1.getTime() - d2.getTime());
    const twoDaysInMilliseconds = dayCount * 24 * 60 * 60 * 1000;
    return timeDifference > twoDaysInMilliseconds;
  };

  const handelClose = () => {
    const date = new Date();
    dispatch(setPreferenceDate(date));
    setIsOpen(false);
  };

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const toggleTableShow = () => {
    dispatch(setShowOnlyTable(!showOnlyTable));
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!preferenceDate) {
      setIsOpen(true);
    } else {
      setIsOpen(compareDates(preferenceDate, new Date()));
    }
  }, [preferenceDate, isAuthenticated]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target.scrollTop > 50) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className={`${currentTheme} h-screen overflow-y-auto`}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        className="z-[1000]"
        theme={currentTheme === "light" ? "light" : "dark"}
      />
      <Header />
      <main className="bg-[var(--bg-color)] h-[calc(100vh-65px)] w-full">
        <div ref={scrollContainerRef} className="overflow-y-auto h-full">
          <Outlet />

          {isAuthenticated && isOpen && (
            <PreferenceModal isOpen={isOpen} onClose={handelClose} />
          )}
        </div>

        {showBackToTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-5 right-5 lg:right-10 w-10 h-10 bg-[var(--bg-color)] border border-[var(--text-color-base)] text-[var(--text-color-base)] shadow-lg transition-all duration-[3000] rounded-lg"
          >
            <KeyboardArrowUpIcon />
          </button>
        )}
        <div className="fixed flex items-center gap-1 h-10 bottom-5 left-5 bg-[var(--bg-color)] border border-[var(--text-color-base)] text-[var(--text-color-base)] shadow-lg transition-all duration-[3000] rounded-lg">
          <button
            onClick={toggleTableShow}
            disabled={!currentCategory && location.pathname === "/"}
            className={`w-10 p-[10px] flex items-center justify-center border-[var(--text-color-base)] h-full disabled:opacity-20 disabled:cursor-not-allowed ${
              showOnlyTable ? "opacity-20" : "text-[var(--text-color-base)]"
            }   `}
          >
            <TableRowsIcon fontSize="16px" />
          </button>
          <button
            disabled={currentCategory}
            className={`w-10 p-[10px] flex items-center justify-center text-[var(--text-color-base)] border-x border-[var(--text-color-base)] h-full disabled:opacity-20 disabled:cursor-not-allowed`}
          >
            <FeedIcon fontSize="16px" />
          </button>
          <button
            disabled={!isAuthenticated}
            className={`w-10 p-[10px] flex items-center justify-center h-full disabled:opacity-20 disabled:cursor-not-allowed `}
          >
            <Favorite fontSize="16px" />
          </button>
        </div>
      </main>
    </div>
  );
}

import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    resizeCanvas,
    getMousePos,
    isInsideBubble,
    drawBubbles,
    createBubbles,
    animateBubbles,
} from '../utils/canvas';
import { setToolModalOpen, updateSelectedTool } from '../reducers/toolsReducer';

const useCanvas = (
    type,
    categories,
    setCurrentCategory,
) => {
    const dispatch = useDispatch();
    const canvasRef = useRef(null);
    const animationCompleted = useRef(false); // Ref to track animation state
    const bubblesRef = useRef([]); // Ref to store bubbles

    // Access the theme from Redux store
    const { currentTheme } = useSelector((state) => state.theme);
    const { collection } = useSelector((state) => state.global);
    const { toolModalOpen } = useSelector((state) => state.tools);

    // Access the selectedTool from Redux store
    const { selectedTool } = useSelector((state) => state.tools);

    const handleBubbleClick = (bubble) => {
        if (type === 'CATEGORY') {
            // Find the matching category by _id instead of index
            const matchingCategory = categories.find(cat => cat._id === bubble._id);
            if (matchingCategory) {
                setCurrentCategory(matchingCategory._id);
            } else {
                console.error('No matching category found for bubble:', bubble);
            }
        } else {
            // Find the matching tool by _id
            const matchingTool = categories.find(sub => sub._id === bubble._id);
            if (matchingTool) {
                dispatch(updateSelectedTool(matchingTool));
                bubble.isBlinking = true;
                dispatch(setToolModalOpen(true))
            } else {
                console.error('No matching tool found for bubble:', bubble);
            }
        }
    };


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;

        // Function to clear canvas and set background color
        const clearCanvas = () => {
            context.fillRect(0, 0, canvas.width, canvas.height);
        };

        const baseBubbleSize = type === 'CATEGORY' ? 60 : 40;
        const padding = 50;

        const handleClick = (evt) => {
            const mousePos = getMousePos(canvas, evt);
            bubblesRef.current.forEach((bubble, index) => {
                if (isInsideBubble(mousePos, bubble)) {
                    handleBubbleClick(bubble, index);
                }
            });
        };

        const handleMouseMove = (evt) => {
            const mousePos = getMousePos(canvas, evt);
            let hovering = false;
            bubblesRef.current.forEach((bubble) => {
                if (isInsideBubble(mousePos, bubble)) {
                    bubble.targetSize = bubble.size * 1.2;
                    hovering = true;
                } else {
                    bubble.targetSize = bubble.size;
                }
            });
            canvas.style.cursor = hovering ? 'pointer' : 'default';
        };

        const initializeCanvas = () => {
            resizeCanvas(canvas, padding);
            bubblesRef.current = createBubbles(type, categories, canvas, baseBubbleSize, padding, currentTheme);
            clearCanvas();
            drawBubbles(context, bubblesRef.current, currentTheme, collection);

            // Start the animation
            requestAnimationFrame(() =>
                animateBubbles(bubblesRef.current, context, canvas, drawBubbles, currentTheme, collection)
            );

            // Start the hover effect rendering loop
            const renderLoop = () => {
                drawBubbles(context, bubblesRef.current, currentTheme, collection);
                requestAnimationFrame(renderLoop); // Keep the loop going
            };
            renderLoop();
        };

        initializeCanvas();

        window.addEventListener('resize', initializeCanvas);
        canvas.addEventListener('click', handleClick);
        canvas.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('resize', initializeCanvas);
            canvas.removeEventListener('click', handleClick);
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, [categories, setCurrentCategory, type, currentTheme]);

    // New useEffect to observe toolModalOpen and stop blinking
    useEffect(() => {
        if (!toolModalOpen) {
            // Turn off blinking for all bubbles when the modal closes
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');
                if (context) {
                    bubblesRef.current.forEach(bubble => {
                        bubble.isBlinking = false; // Stop blinking
                    });
                    drawBubbles(context, bubblesRef.current, currentTheme, collection); // Redraw bubbles
                }
            }
        }
    }, [toolModalOpen, currentTheme]); // Depend on toolModalOpen and currentTheme changes

    // UseEffect to update bubbles based on selectedTool
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (context) {
                // Check if selectedTool is not null
                if (selectedTool) {
                    bubblesRef.current.forEach((bubble) => {
                        if (bubble._id === selectedTool._id) {
                            // Start blinking effect for the selected bubble
                            bubble.isBlinking = true;
                            bubble.targetSize = bubble.size * 1.2; // Enlarge selected bubble
                        } else {
                            bubble.isBlinking = false; // Stop blinking for other bubbles
                            bubble.targetSize = bubble.size; // Reset other bubbles to their normal size
                        }
                    });
                } else {
                    // If selectedTool is null, stop blinking and reset all bubbles
                    bubblesRef.current.forEach((bubble) => {
                        bubble.isBlinking = false; // Stop blinking
                        bubble.targetSize = bubble.size; // Reset size to default
                    });
                }

                // Redraw the bubbles after modifying the state
                drawBubbles(context, bubblesRef.current, currentTheme, collection);
            }
        }
    }, [selectedTool, currentTheme, collection]);


    return canvasRef;
};

export default useCanvas;

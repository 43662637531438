import { Field, Label, RadioGroup, Radio } from "@headlessui/react";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const PaginationRangeControl = ({
  paginationRanges,
  selectedRange,
  onChangeRange,
  close,
}) => {
  return (
    <Field>
      <Label className="text-lg font-bold text-[var(--text-color-base)] block mb-3">
        Pagination Range
      </Label>
      <RadioGroup
        value={selectedRange}
        onChange={(value) => onChangeRange(value, close)}
        aria-label="Pagination Content"
        className="space-y-2 mt-1"
      >
        {paginationRanges.map(({ label }, index) => (
          <Radio
            key={index}
            value={label}
            className="flex cursor-pointer text-[var(--text-color-base)] transition focus:outline-none data-[focus]:outline-none"
          >
            <div className="flex w-full items-center gap-2">
              {selectedRange === label ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <div className="text-lg">
                <p className="font-medium text-[var(--text-color-base)]">
                  {label}
                </p>
              </div>
            </div>
          </Radio>
        ))}

        <Radio
          value="custom"
          className="flex cursor-pointer text-[var(--text-color-base)] transition focus:outline-none data-[focus]:outline-none"
        >
          <div className="flex w-full items-center gap-2">
            {selectedRange === "custom" ? (
              <RadioButtonChecked />
            ) : (
              <RadioButtonUnchecked />
            )}
            <div className="text-lg">
              <p className="font-medium text-[var(--text-color-base)]">
                Custom Range
              </p>
            </div>
          </div>
        </Radio>
      </RadioGroup>
    </Field>
  );
};

export default PaginationRangeControl;

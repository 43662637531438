import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonIcon from "@mui/icons-material/Person";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AuthModal from "../../components/modals/auth-modal";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";

const TrendingOptions = ({ setIsMobileHeaderOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const LOCATION_PATHNAME = location?.pathname;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { currentTheme } = useSelector((state) => state.theme);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Recent");

  const options = [
    {
      label: "Recent",
      value: "/",
      icon: AccessTimeIcon,
    },
    {
      label: "Popular",
      value: "/popular",
      icon: TrendingUpIcon,
    },
    {
      label: "For You",
      value: "/for-you",
      icon: PersonIcon,
    },
  ];

  const VALID_PATH = useMemo(() => ["/popular", "/for-you"], []);

  useEffect(() => {
    if (!VALID_PATH.includes(LOCATION_PATHNAME)) {
      setActiveIndex(0);
      setSelectedOption("Recent");
    }
  }, [LOCATION_PATHNAME, VALID_PATH]);

  const handleTabChange = async (option, index) => {
    setIsMobileHeaderOpen(false);
    setActiveIndex(index);
    setSelectedOption(option.label);
    if (!isAuthenticated && option?.value === "/for-you") {
      setOpenModal(true);
      return;
    }
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false));
    navigate(option?.value);
  };

  return (
    <Fragment>
      <Menu as="div" className="relative">
        {({ open }) => {
          setIsMenuOpen(open);

          return (
            <Fragment>
              <MenuButton
                className={`w-max h-max border hover:border-[var(--primary-color)] rounded-full px-3 py-1 ${
                  LOCATION_PATHNAME === "/" ||
                  LOCATION_PATHNAME === "/for-you" ||
                  LOCATION_PATHNAME === "/popular" ||
                  isMenuOpen
                    ? "text-[var(--primary-color)] border-[var(--primary-color)] font-bold"
                    : "text-[var(--text-color-base)]"
                }`}
              >
                <div className="flex gap-1 items-center">
                  <p>{selectedOption ?? "Recent"} </p>
                  {isMenuOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className={`${currentTheme} z-[1000] mt-2 lg:mt-4 ml-5 lg:ml-0 p-2 w-48 rounded-lg shadow-filter backdrop-blur origin-top-right transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0`}
              >
                {options.map((option, index) => (
                  <MenuItem key={index}>
                    <button
                      onClick={() => handleTabChange(option, index)}
                      className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-white
                        ${
                          activeIndex === index
                            ? "bg-[var(--primary-color)] font-bold"
                            : "bg-transparent"
                        }
                  `}
                    >
                      <option.icon />
                      {option.label}
                    </button>
                  </MenuItem>
                ))}
              </MenuItems>
            </Fragment>
          );
        }}
      </Menu>
      <AuthModal open={openModal} setOpen={setOpenModal} />
    </Fragment>
  );
};

export default TrendingOptions;

import { useEffect } from "react";
import CategoryBubble from "../../components/bubbles/category-bubble";
import { useDispatch } from "react-redux";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { useFetchBubbles } from "../../hooks/useFetchBubbles";

function Categories() {
  const dispatch = useDispatch();
  const { loading, categories, error } = useFetchBubbles("category");

  useEffect(() => {
    dispatch(setShowOnlyTable(false));
  }, [dispatch]);

  return (
    <div className={`relative w-full h-[calc(100vh-65px)]`}>
      <CategoryBubble
        categories={categories}
        loading={loading}
        error={error}
        setCurrentCategory={(data) => {
          dispatch(setCurrentCategory(data));
        }}
      />
    </div>
  );
}

export default Categories;

import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { setBlueprints } from "../../reducers/globalReducer";

const Pagination = ({ children }) => {
  const dispatch = useDispatch();

  const blueprints = useSelector((state) => state.global.blueprints); // Get the full blueprints object
  const { currentPage, totalPages } = blueprints;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(setBlueprints({ ...blueprints, currentPage: currentPage - 1 }));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setBlueprints({ ...blueprints, currentPage: currentPage + 1 }));
    }
  };

  return (
    <div className="flex items-center">
      {currentPage > 1 && (
        <button
          className="h-10 w-10 rounded-full text-white bg-[#1B1A23]"
          onClick={handlePreviousPage}
        >
          <ChevronLeftIcon />
        </button>
      )}
      {children}
      {currentPage < totalPages && (
        <button
          className="h-10 w-10 rounded-full text-white bg-[#1B1A23]"
          onClick={handleNextPage}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
};

export default Pagination;

import React, { Fragment, useEffect, useMemo, useState } from "react";
import axios from "axios";
import MemoryIcon from "@mui/icons-material/Memory";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CollectionMap } from "../../enums/collections";
import {
  resetBlueprints,
  setCollection,
  setCurrentCategory,
} from "../../reducers/globalReducer";
import { nodeServerPath } from "../../constant/api";

const AiCategories = ({ setIsMobileHeaderOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentTheme } = useSelector((state) => state.theme);
  const LOCATION_PATHNAME = location?.pathname;
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("AI Categories");

  const options = [
    {
      label: "AI Tools",
      value: "ToolTab",
      icon: MemoryIcon,
    },
    {
      label: "Tasks",
      value: "TaskTab",
      icon: TaskOutlinedIcon,
    },
    {
      label: "GPTs",
      value: "GPTTab",
      icon: WorkspacesOutlinedIcon,
    },
    {
      label: "Plugins",
      value: "PluginTab",
      icon: ExtensionOutlinedIcon,
    },
    {
      label: "YCombinator",
      value: "YCombinatorTab",
      icon: WysiwygIcon,
    },
    {
      label: "Crypto",
      value: "CryptoTab",
      icon: CurrencyBitcoinOutlinedIcon,
    },
    {
      label: "Social",
      value: "SocialTab",
      icon: ConnectWithoutContactOutlinedIcon,
    },
  ];

  const VALID_PATH = useMemo(() => ["/ai"], []);

  useEffect(() => {
    if (!VALID_PATH.includes(LOCATION_PATHNAME)) {
      setActiveIndex(null);
      setSelectedOption("AI Categories");
    }
  }, [LOCATION_PATHNAME, VALID_PATH]);

  const handleTabChange = async (option, index) => {
    setIsMobileHeaderOpen(false);
    setSelectedOption(option.label);
    dispatch(resetBlueprints());
    const collectionName = CollectionMap[option.value];

    try {
      await axios.post(`${nodeServerPath}/api/setCollection`, {
        collectionName,
      });
      // Update Redux store after successful API call
      dispatch(setCollection(option.value));
      dispatch(setCurrentCategory(null));

      // Perform navigation after state updates
      navigate(`/ai?collection=${option.value}`);
      setActiveIndex(index);
    } catch (error) {
      console.error("Error setting collection:", error.response || error);
    }
  };

  return (
    <Menu as="div" className="relative">
      {({ open }) => {
        setIsMenuOpen(open);

        return (
          <Fragment>
            <MenuButton
              className={`w-max h-max border hover:border-[var(--primary-color)] rounded-full px-3 py-1 ${
                location.pathname === "/ai" || isMenuOpen
                  ? "text-[var(--primary-color)] border-[var(--primary-color)] font-bold"
                  : "text-[var(--text-color-base)]"
              }`}
            >
              <div className="flex gap-1 items-center">
                <p>{selectedOption}</p>
                {isMenuOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </MenuButton>

            <MenuItems
              transition
              anchor="bottom end"
              className={`${currentTheme} z-[1000] mt-2 lg:mt-4 ml-5 lg:ml-0 p-2 w-48 rounded-lg shadow-filter backdrop-blur origin-top-right transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0`}
            >
              {options.map((option, index) => (
                <MenuItem key={index}>
                  <button
                    onClick={() => handleTabChange(option, index)}
                    className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-white
                        ${
                          activeIndex === index
                            ? "bg-[var(--primary-color)] font-bold"
                            : "bg-transparent"
                        }
                  `}
                  >
                    {/* <AccountCircle fontSize="small" /> */}
                    <option.icon />
                    {option.label}
                  </button>
                </MenuItem>
              ))}
            </MenuItems>
          </Fragment>
        );
      }}
    </Menu>
  );
};

export default AiCategories;

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { ClickAwayListener } from "@mui/material";
import GlobalSearch from "../../components/forms/global-search";
import PaginationDropdown from "../../components/pagination";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";

export default function HeaderLeft() {
  const dispatch = useDispatch();
  const { currentTheme } = useSelector((state) => state.theme);
  const [openSearch, setOpenSearch] = useState(false);

  const clearIndex = () => {
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false));
  };
  const searchBoxOpenRef = useRef(null);

  const handleFocusOut = (e) => {
    if (!searchBoxOpenRef.current.contains(e.relatedTarget)) {
      setOpenSearch(false);
    }
  };

  return (
    <div className="flex items-center gap-2 sm:gap-5">
      <Link to="/" onClick={clearIndex}>
        <img
          src={
            currentTheme === "light"
              ? "/assets/logo-light.png"
              : "/assets/logo-dark.png"
          }
          className="w-10 h-10 min-w-[2.5rem] min-h-[2.5rem] shrink-0"
          alt="Logo"
        />
      </Link>
      <ClickAwayListener onClickAway={() => setOpenSearch(false)}>
        <div className="block xl:hidden">
          {openSearch ? (
            <div
              ref={searchBoxOpenRef}
              tabIndex={-1}
              onBlur={handleFocusOut}
              onFocus={() => setOpenSearch(true)}
              className="relative"
            >
              <GlobalSearch />
            </div>
          ) : (
            <button
              className="h-10 w-10 rounded-full text-white bg-[#1B1A23]"
              onClick={() => setOpenSearch(true)}
              aria-label="Open search"
            >
              <SearchIcon />
            </button>
          )}
        </div>
      </ClickAwayListener>
      <div className="hidden xl:block">
        <GlobalSearch />
      </div>
      <div className={`${openSearch ? "hidden" : "block"}`}>
        <PaginationDropdown />
      </div>
    </div>
  );
}

import { useEffect, useMemo } from "react";
import ToolBubble from "../../components/bubbles/tool-bubble";
import { useDispatch } from "react-redux";
import { setShowOnlyTable } from "../../reducers/globalReducer";
import { useSearchParams } from "react-router-dom";
import { updateSelectedTool } from "../../reducers/toolsReducer";
import { useFetchBubbles } from "../../hooks/useFetchBubbles";

function Tools() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { loading, tools, error } = useFetchBubbles("category-tools");

  const { toolName } = useMemo(() => {
    return {
      toolName: searchParams.get("tool") || null,
    };
  }, [searchParams]);

  useEffect(() => {
    const isMatchedTool = tools.some((toolItem) => toolItem.name === toolName);
    const matchingTool = tools.find((toolItem) => toolItem.name === toolName);
    if (isMatchedTool) {
      dispatch(updateSelectedTool(matchingTool));
    }
  }, [toolName, tools, dispatch]);

  useEffect(() => {
    dispatch(setShowOnlyTable(false));
  }, [dispatch]);

  return <ToolBubble {...{ tools, loading, error }} />;
}

export default Tools;

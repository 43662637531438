import React from "react";
import useCanvas from "../../hooks/useCanvasBubbles";
import Loader from "../snippets/loader";
import { useSelector } from "react-redux";

const CategoryBubble = ({ categories, setCurrentCategory, loading, error }) => {
  const canvasRef = useCanvas("CATEGORY", categories, setCurrentCategory);
  const { currentTheme } = useSelector((state) => state.theme);

  console.log("loading", loading);

  if (loading) {
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Some thing is not working fine</div>;
  }

  if (categories.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <h4 className="text-base text-[var(--text-color-base)]">
          No Categories found!
        </h4>
      </div>
    );
  }

  return (
    <canvas
      className={`${
        currentTheme === "light"
          ? "bg-[var(--bg-color)] bg-cover bg-no-repeat"
          : "bg-[#111117]"
      } bg-contain h-full`}
      ref={canvasRef}
      style={{
        backgroundImage: `${
          currentTheme === "light" ? "url('/assets/banner-light-bg.png')" : ""
        }`,
        width: "100%",
      }}
    />
  );
};

export default CategoryBubble;

import React, { useRef } from "react";
import { Circle } from "@mui/icons-material";

const FavoritesCards = ({ tools, setParams, loading, blueprints }) => {
  const observer = useRef();

  tools = tools?.map((tool) => {
    return {
      ...tool,
      image:
        "https://gaine.com/wp-content/uploads/2023/07/Probabilistic-and-Deterministic-Results-in-AI-Systems.jpg",
    };
  });

  const lastItemRef = (node) => {
    if (loading || blueprints.totalPages <= blueprints.page) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setParams();
      }
    });
    if (node) observer.current.observe(node);
  };

  return (
    <div className="w-full py-5">
      <div className="w-full flex flex-col gap-10">
        {tools.map((tool, key) => (
          <div
            ref={key === tools.length - 1 ? lastItemRef : null}
            className="w-full flex flex-col gap-4"
            key={key}
          >
            <div className="flex items-center gap-2">
              <Circle fontSize="small" className="text-[#9C3CD6]" />
              <h3 className="text-lg">
                {tool?.toolDetails.category}:
                {tool?.toolDetails?.rank?.upvotes && (
                  <span className="px-3 py-1 bg-[#9C3CD6]/10 rounded-lg ml-2">
                    {tool.toolDetails.rank.upvotes}
                  </span>
                )}
              </h3>
            </div>
            <div
              className="w-full bg-gray-400/20 backdrop-blur rounded-lg p-5 flex flex-col gap-4 shadow"
              key={key}
            >
              <div className="flex flex-col">
                <div className="flex items-center justify-between gap-2">
                  <h1 className="text-2xl">{tool.toolName}</h1>
                  <h5 className="text-sm/6 opacity-70">
                    {new Date(
                      tool.toolDetails.last_update_date
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </h5>
                </div>
                <p className="text-base opacity-70">
                  {tool.toolDetails.description}
                </p>
              </div>

              <img
                src={tool.image}
                alt={tool.toolName}
                className="w-full aspect-[16/6] object-cover rounded-lg"
              />
            </div>
          </div>
        ))}
      </div>

      {loading && <div className="p-5 text-center">Loading Tools...</div>}
    </div>
  );
};

export default FavoritesCards;

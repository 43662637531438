export const CollectionMap = Object.freeze({
    ToolTab: "FutureTools",
    TaskTab: "TheresAnAIForThat",
    GPTTab: "GPTStore",
    PluginTab: "GPTPlugins",
    ProductHuntTab: "ProductHunt",
    YCombinatorTab: "YCombinator",
    CryptoTab: "Crypto",
    SocialTab: "Social",
  });
  
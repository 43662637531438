import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { formatSentence } from "../../utils";

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        color: "black", // Customize the color if needed
        cursor: "pointer",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon
        className="text-[var(--text-color-base)]"
        style={{ fontSize: "24px" }}
      />
    </div>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        color: "black", // Customize the color if needed
        cursor: "pointer",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackIosIcon
        className="text-[var(--text-color-base)]"
        style={{ fontSize: "24px" }}
      />
    </div>
  );
}

const SimilarTools = ({ similarTools }) => {
  const navigate = useNavigate();
  const sliderSettings = {
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  const onClickTool = (singleTool) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    navigate(
      `/${formatSentence(singleTool?.name)}/details?hash=${singleTool?.hash}`
    );
  };

  return (
    <Fragment>
      <h3 className="mb-5 font-bold text-2xl text-[var(--text-color-base)] text-center">
        Similar Tools
      </h3>
      <Slider {...sliderSettings}>
        {similarTools.map((singleTool) => (
          <div
            key={singleTool._id}
            onClick={() => onClickTool(singleTool)}
            className="w-full min-h-[140px] mb-5 bg-[var(--bg-color)] hover:bg-gray-400/40 shadow-[var(--shadow-color)] cursor-pointer backdrop-blur rounded-lg p-4 flex flex-col gap-3 shadow transition-all"
          >
            <div className="flex flex-col">
              <div className="flex items-center justify-between gap-2 mb-4">
                <h1 className="text-lg whitespace-nowrap text-ellipsis text-[var(--text-color-base)]">
                  {singleTool.name}
                </h1>
                <h5 className="text-xs/6 opacity-70 whitespace-nowrap text-[var(--text-color-base)]">
                  {new Date(singleTool.last_update_date).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </h5>
              </div>
              {singleTool?.globalRank && (
                <h4 className="text-base text-[var(--primary-color)]">
                  Global Rank: {singleTool.globalRank}
                </h4>
              )}
              <p className="text-sm opacity-70 text-[var(--text-color-base)]">
                {singleTool.description}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

export default SimilarTools;

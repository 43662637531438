import React from "react";
import { Link } from "react-router-dom";
import AiCategories from "./ai-categories";
import TrendingOptions from "./trending-options";
import NewspaperIcon from "@mui/icons-material/Newspaper";

export default function NavbarItems({ setIsMobileHeaderOpen }) {
  return (
    <div className="flex flex-col item-start lg:items-center lg:flex-row gap-4">
      <TrendingOptions setIsMobileHeaderOpen={setIsMobileHeaderOpen} />
      <AiCategories setIsMobileHeaderOpen={setIsMobileHeaderOpen} />
      <Link
        to="http://ai-bubbles.com:8080"
        target="_blank"
        className="w-max flex items-center gap-2 text-white font-semibold px-3 py-2 rounded-full bg-clip-padding bg-[var(--primary-color)] overflow-hidden whitespace-nowrap text-ellipsis transition-all"
      >
        <NewspaperIcon />
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          News
        </span>
      </Link>
    </div>
  );
}

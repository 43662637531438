import React from "react";
import ToolBubble from "../../components/bubbles/tool-bubble";
import { useFetchBubbles } from "../../hooks/useFetchBubbles";

const RecentPage = () => {
  const { loading, tools, error } = useFetchBubbles("recent");

  return <ToolBubble {...{ tools, loading, error }} />;
};

export default RecentPage;

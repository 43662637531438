import { Field, Label, Input, Button } from "@headlessui/react";
import { useSelector } from "react-redux";

const CustomRangeControl = ({
  selectedRange,
  startNumber,
  setStartNumber,
  handleRangeApply,
}) => {
  const { blueprints } = useSelector((state) => state.global);
  if (selectedRange !== "custom") return null;

  return (
    <div className="flex items-end gap-5 ml-8 mb-5">
      <Field>
        <Label className="text-lg font-bold text-[var(--text-color-base)]">
          From:
        </Label>
        <Input
          type="number"
          min={1}
          step={1}
          value={startNumber}
          onChange={(e) => setStartNumber(Number(e.target.value))}
          className="mt-1 block max-w-[100px] rounded-lg border-none bg-white/50 shadow py-1 px-3 text-lg font-normal text-[var(--text-color-base)] focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-bgColor/25"
        />
      </Field>

      <Field>
        <Label className="text-lg font-bold text-[var(--text-color-base)]">
          To:
        </Label>
        <div className="mt-1 block w-full rounded-lg border-none bg-white/50 shadow py-1 px-3 text-lg font-normal text-[var(--text-color-base)]">
          {Number(startNumber + blueprints.perPage) - 1}
        </div>
      </Field>

      <Button
        className={`text-[var(--text-color-base)] hover:text-[var(--primary-color)] border hover:border-[var(--primary-color)] rounded-full px-4 py-[5px] whitespace-nowrap transition-all`}
        onClick={handleRangeApply}
      >
        Apply
      </Button>
    </div>
  );
};

export default CustomRangeControl;

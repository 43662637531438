import { Button, Field, Input, Label } from "@headlessui/react";

const ItemPerPageControl = ({
  itemPerPage,
  setItemPerPage,
  handlePerPageApply,
}) => {
  return (
    <Field>
      <Label className="text-lg font-bold text-[var(--text-color-base)]">
        Item per page:
      </Label>
      <div className="flex items-center">
        <Input
          type="number"
          min={1}
          step={1}
          placeholder={0}
          value={itemPerPage}
          onChange={(e) => {
            setItemPerPage(Number(e.target.value));
          }}
          className="mt-1 mr-2 block w-full rounded-lg border-none bg-white/50 shadow py-1.5 px-3 text-lg font-normal text-[var(--text-color-base)] focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-bgColor/25"
        />
        <Button
          className={`text-[var(--text-color-base)] hover:text-[var(--primary-color)] border hover:border-[var(--primary-color)] rounded-full px-4 py-[5px] whitespace-nowrap transition-all`}
          onClick={handlePerPageApply}
        >
          Apply
        </Button>
      </div>
    </Field>
  );
};

export default ItemPerPageControl;

import React, { Fragment } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useSelector } from "react-redux";
import Pagination from "../snippets/pagination";
import PaginationRangeControl from "./range-control";
import ItemPerPageControl from "./item-per-page-control";
import CustomRangeControl from "./custom-range-control";
import usePaginationDropdown from "../../hooks/usePaginationDropdown";

const PaginationDropdown = () => {
  const { currentTheme } = useSelector((state) => state.theme);

  const {
    totalItems,
    startValue,
    endValue,
    itemPerPage,
    setItemPerPage,
    handlePerPageApply,
    paginationRanges,
    selectedRange,
    onChangeRange,
    startNumber,
    setStartNumber,
    handleRangeApply,
  } = usePaginationDropdown();

  return (
    <Popover>
      {({ close }) => (
        <Fragment>
          <div className="ml-1">
            <Pagination>
              <PopoverButton title="Filter" className="">
                <div className="flex items-center gap-1 flex-nowrap bg-[#1B1A23] mx-2 text-white rounded-full px-5 py-2 whitespace-nowrap">
                  <p className="whitespace-nowrap">
                    {startValue}-{endValue}
                  </p>
                  <p className="hidden sm:block whitespace-nowrap">
                    {" "}
                    of {totalItems}
                  </p>
                </div>
              </PopoverButton>
            </Pagination>
          </div>

          <PopoverPanel
            transition
            anchor="bottom start"
            className={`${currentTheme} z-10 min-w-[248px] mt-1 rounded-lg shadow-filter backdrop-blur transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0`}
          >
            <div className="w-full p-4 grid grid-flow-row gap-5">
              <div className="flex justify-between items-start">
                <ItemPerPageControl
                  {...{ itemPerPage, setItemPerPage, handlePerPageApply }}
                />

                <button
                  className="text-white cursor-pointer"
                  onClick={() => close()}
                >
                  Close
                </button>
              </div>

              <PaginationRangeControl
                {...{ paginationRanges, selectedRange, onChangeRange, close }}
              />

              <CustomRangeControl
                {...{
                  selectedRange,
                  startNumber,
                  setStartNumber,
                  handleRangeApply,
                }}
              />
            </div>
          </PopoverPanel>
        </Fragment>
      )}
    </Popover>
  );
};

export default PaginationDropdown;

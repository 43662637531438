import React, { useEffect, useState } from "react";
import { Button, Description, Field, Input, Label } from "@headlessui/react";
import ProfileLayout from "../../../layouts/profile";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../utils/api/user";
import { loginSuccess } from "../../../reducers/authReducer";
import { setBlueprints } from "../../../reducers/globalReducer";

function SettingsPage() {
  const user = useSelector((state) => state.auth.user);
  const { blueprints } = useSelector((state) => state.global);
  const [bubblesPerPage, setBubblesPerPage] = useState(100);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    setBubblesPerPage(user?.bubblesPerPage);
    setBlueprints({
      ...blueprints,
      perPage: user?.bubblesPerPage, // Update perPage
    });
  }, [user]);

  const onUpdated = (data) => {
    dispatch(loginSuccess(data));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await updateUser(
      user,
      { bubblesPerPage: Number(bubblesPerPage) },
      onUpdated
    );
  };

  return (
    <ProfileLayout>
      <form
        className="w-full h-full flex flex-col justify-between"
        onSubmit={onSubmit}
      >
        <div className="w-full grid grid-flow-col gap-5 grid-cols-2">
          <Field>
            <Label className="text-lg font-bold text-[var(--text-color-base)]">
              Items per page:
            </Label>
            <Description className="text-sm font-light text-[var(--text-color-base)] opacity-70">
              Choose the amount of data to display per page.
            </Description>
            <Input
              type="number"
              min={1}
              step={1}
              placeholder={0}
              value={bubblesPerPage}
              onChange={(e) => setBubblesPerPage(e.target.value)}
              className="mt-1 block w-full rounded-lg border-none bg-white/50 shadow py-1.5 px-3 text-lg font-normal text-[var(--text-color-base)] focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-bgColor/25"
            />
          </Field>
        </div>
        <div className="w-full flex items-center justify-end">
          <Button
            type="submit"
            className="w-auto px-10 py-2 rounded-full bg-[var(--bg-color)] text-md text-[var(--text-color-base)] shadow-lg hover:bg-[var(--primary-color)] hover:text-white transition-all"
          >
            Submit
          </Button>
        </div>
      </form>
    </ProfileLayout>
  );
}

export default SettingsPage;
